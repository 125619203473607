/* FONTS */

@font-face {
  font-family: "Open Sans";
  src: url("./assets/fonts/OpenSans-Bold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Open Sans";
  src: url("./assets/fonts/OpenSans-BoldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Open Sans";
  src: url("./assets/fonts/OpenSans-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Open Sans";
  src: url("./assets/fonts/OpenSans-Italic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Familjen Grotesk";
  src: url("./assets/fonts/FamiljenGroteskGF-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Familjen Grotesk";
  src: url("./assets/fonts/FamiljenGroteskGF-BoldItalic.ttf") format("truetype");
  font-weight: 700;
  font-style: italic;
}

/* PAGE */

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

html,
body {
  position: relative;
  padding: 0;
  margin: 0;
  font-family: "Open Sans";
  font-size: 18px;
  font-weight: 600;
}

/* TEXTS */

/* Headline 1 */
h1 {
  font-family: "Familjen Grotesk";
  font-weight: 700;
  font-size: 32px;
  margin: 0;
}

/* Subtitle 1 */
h2 {
  font-family: "Familjen Grotesk";
  font-weight: 600;
  font-size: 20px;
  margin: 0;
}

/* Subtitle 2 */
h3 {
  font-family: "Familjen Grotesk";
  font-weight: 400;
  font-size: 20px;
  margin: 0;
}

/* Body 2 */
p {
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 18px;
  margin: 0;
  line-height: 150%;
}

/* Body 1, Caption 1 */
strong,
b {
  font-weight: 600;
}

/* Caption 2 */
label {
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 14px;
  margin: 0;
  line-height: 150%;
}

@media (max-width: 800px) {
  h1 {
    font-size: 26px;
  }
}

a {
  color: #4059ad;
}
